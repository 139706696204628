
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {Component} from "vue-property-decorator";
import {validationMixin} from "vuelidate";
import {FeedStoreActions, FeedStoreGetters, INFO_FEED_STORE_NAME} from "@/store/feed.store";
import {namespace} from "vuex-class";
import News from "@/models/News.model";
import {minLength} from "vuelidate/lib/validators";
import Company from "@/models/Company";
import {COMPANY_STORE_NAME, CompanyStoreGetters} from "@/store/company.store";
import {APPLICATION_STORE_NAME, ApplicationStoreGetters} from "@/store/application.store";

const FeedStore = namespace(INFO_FEED_STORE_NAME);
const CompanyStore = namespace(COMPANY_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {
    NavigationDrawerComponent: () => import(
      /* webpackChunkName: "NavigationDrawerComponent" */
      '@/components/Misc/NavigationDrawer.component.vue'
    ),
    NewsListComponent: () => import(
      /* webpackChunkName: "NewsListComponent" */
      '@/components/News/NewsList.component.vue'
    ),
    CreateNewsComponent: () => import(
      /* webpackChunkName: "CreateNewsComponent" */
      '@/components/News/CreateNews.component.vue'
    ),
    ConfirmDeleteDialog: () => import(
      /* webpackChunkName: "ConfirmDeleteDialog" */
      '@/components/News/ConfirmDeleteNews.component.vue'
    ),
  },
  mixins: [validationMixin],
  validations: {
    infoFeedText: {
      minLength: minLength(3),
      // checks if the string has at least one character
      containsCharacters: function (value) {
        return /\S/.test(value);
      }
    },
  }
})
export default class InfoFeedView extends mixins(ErrorMessageHandlerMixin) {
  @FeedStore.Action(FeedStoreActions.GET)
  private loadInfoFeed!: (companyId: string) => Promise<News[]>;

  @FeedStore.Getter(FeedStoreGetters.FEED)
  private getInfoFeedsAction!: () => Promise<News[]>;

  @FeedStore.Action(FeedStoreActions.UPDATE)
  private updateFeedAction!: (feed: string | null) => Promise<string | null>;

  @FeedStore.Action(FeedStoreActions.CREATE)
  private createNewsAction!: (feed: News) => Promise<News>;

  @FeedStore.Action(FeedStoreActions.DELETE)
  private deleteNewsAction!: (id: string) => Promise<News[]>;

  @CompanyStore.Getter(CompanyStoreGetters.COMPANIES)
  private companies!: Company[];

  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_COMPANY)
  private currentCompany?: Company;

  /**
   * the news that was selected for opening the modal
   */
  private selectedNews: News | null = null;

  /**
   * flag that handles if the update news modal should be shown
   */
  private showUpdateNewsModal = false;

  private showCreateNewsModal = false;

  /**
   * text of the input field that allows to create new news
   */
  private infoFeedText: string | null = '';

  /**
   * value of the search field
   */
  private search: string = '';

  /**
   * is loading flag to show loading animation inside the table
   */
  private isLoading = false;

  /**
   * the feeds that should be shown inside the data table
   */
  private feeds: News[] = [];

  private isDuplicating: boolean = false;

  /**
   * flag that indicates if the confirm delete dialog should be shown
   */
  private showDeleteDialog: boolean = false;

  private deletedNews!: string;

  /**
   * loads the feed from the store
   */
  public async mounted() {
    try {
      this.isLoading = true;

      // gets all the feeds based on the company
      this.feeds = await this.loadInfoFeed(this.$route.params.id);
    } catch (e) {
      this.$handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the user taps on the create message button, opens creation modal
   * @private
   */
  private async createMessage() {
    this.showCreateNewsModal = true;
  }

  /**
   * updates a news, finds the news based on the id and shows editing modal
   */
  private async updateNews(id: string) {
    const feed = this.feeds.find((f) => f.id === id);
    if(!feed) return;

    this.selectedNews = feed;
    this.showUpdateNewsModal= true;
  }

  /**
   * duplicates a news, gets the news that should be duplicated, uses it as the base object and creates new news based
   * on that
   * @param id
   * @private
   */
  private async duplicateNews(id: string) {
    const feed = this.feeds.find((f) => f.id === id);
    if(!feed) return;

    this.selectedNews = feed;
    this.isDuplicating = true;
    this.showCreateNewsModal = true;
  }

  /**
   * deletes a news with the passed id
   * @param id
   * @private
   */
  private async deleteNews(id: string) {
    this.showDeleteDialog = true;
    this.deletedNews = id;
  }

  private async closeDialog(reload: boolean) {
    this.showCreateNewsModal = false;
    this.showUpdateNewsModal = false;
    this.isDuplicating = false;
    this.selectedNews = null;
    if (reload) {
      try {
        this.feeds = await this.loadInfoFeed(this.$route.params.id);
      } catch (e) {
        this.$handleError(e);
      }
    }
  }

  /**
   * is called when the confirm delete dialog is closed, checks if the item should be deleted and deletes it (if needed)
   * @param deleted
   * @private
   */
  private async closeConfirmDialog(deleted: boolean) {
    this.showDeleteDialog = false;
    if(!deleted) return;

    try {
      await this.deleteNewsAction(this.deletedNews);
    } catch(_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.CANNOT_DELETE_NEWS');
    }
  }
}
